import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faTwitterSquare,
  faFacebookSquare,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons"
import { faEnvelope, faMapMarkerAlt } from "@fortawesome/pro-duotone-svg-icons"

function Footer() {
  return (
    <footer className="cursor-default">
      <div className="container">
        <div className="grid grid-cols-12 gap-2 py-8 md:py-12">
          <div className="col-span-6 sm:col-span-4 lg:col-span-2">
            <h6 className="text-gray-800 font-medium mb-2 text-lg">
              Resources
            </h6>
            <ul className="text-sm">
              <li className="mb-2">
                <a
                  className="text-purple-600  transition duration-150 ease-in-out"
                  href="https://help.keshif.me"
                >
                  Knowledge Base
                </a>
              </li>
              <li>
                <a
                  className="text-purple-600  transition duration-150 ease-in-out"
                  href="https://blog.keshif.me"
                >
                  Blog
                </a>
              </li>
            </ul>
          </div>
          <div className="col-span-6 sm:col-span-8 lg:col-span-2">
            <h6 className="text-gray-800 font-medium mb-2 text-lg">Legal</h6>
            <ul className="text-sm">
              <li className="mb-2">
                <Link
                  className="text-purple-600  transition duration-150 ease-in-out"
                  to="/Terms"
                >
                  Terms of Use
                </Link>
              </li>
              <li>
                <Link
                  className="text-purple-600  transition duration-150 ease-in-out"
                  to="/Privacy"
                >
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-span-6 sm:col-span-4 lg:col-span-2">
            <h6 className="text-gray-800 text-lg font-medium mb-2">Company</h6>
            <ul className="text-sm">
              <li className="mb-2">
                <Link
                  className="text-purple-600  transition duration-150 ease-in-out"
                  to="/AboutUs"
                >
                  About Us
                </Link>
              </li>
              <li>
                <a
                  href="/WorkWithUs"
                  className="text-purple-600  transition duration-150 ease-in-out"
                >
                  Careers
                </a>
              </li>
            </ul>
          </div>
          <div className="col-span-6 sm:col-span-4 lg:col-span-3 font-light">
            <h6 className="text-gray-800 font-medium mb-2 text-lg">Contact</h6>
            <ul className="text-sm">
              <li className="mb-2">
                <a
                  className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
                  href="mailto:info@keshif.me"
                >
                  <FontAwesomeIcon
                    className="text-purple-600 mr-1"
                    icon={faEnvelope}
                    fixedWidth
                  />
                  info@keshif.me
                </a>
              </li>
              <li>
                <address className="text-gray-600 not-italic hover:text-gray-900 transition duration-150 ease-in-out">
                  <FontAwesomeIcon
                    className="text-purple-600 mr-1"
                    icon={faMapMarkerAlt}
                    fixedWidth
                  />
                  Alexandria, VA, US
                </address>
              </li>
            </ul>
          </div>
          <div className="col-span-12 sm:col-span-4 lg:col-span-3 flex flex-col sm:items-end">
            <ol className="flex flex-auto gap-2 py-3">
              <li>
                <a
                  className="flex justify-center items-center text-gray-500 hover:text-gray-900 bg-white hover:bg-white-100 transition duration-150 ease-in-out disable-underline"
                  aria-label="Keshif on LinkedIn"
                  href="https://linkedin.com/company/keshif-llc"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon size="lg" icon={faLinkedin} />
                </a>
              </li>
              <li>
                <a
                  className="flex justify-center items-center text-gray-500 hover:text-gray-900 bg-white hover:bg-white-100 transition duration-150 ease-in-out disable-underline"
                  aria-label="Keshif on Twitter"
                  href="https://twitter.com/keshifme"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon size="lg" icon={faTwitterSquare} />
                </a>
              </li>
              <li>
                <a
                  className="flex justify-center items-center text-gray-500 hover:text-gray-900 bg-white hover:bg-white-100 transition duration-150 ease-in-out disable-underline"
                  aria-label="Keshif on Facebook"
                  href="https://facebook.com/keshifme"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon size="lg" icon={faFacebookSquare} />
                </a>
              </li>
            </ol>
            <div className="text-sm text-gray-600 font-light">
              © 2021. Keshif, LLC.
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
export default Footer
